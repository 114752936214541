#right-side-cart-background {
  .right-cart-summary {
    .totals {
      .total {
        .checkout-url {
          a, a:hover {
            font-size: 11px;
          }
        }
      }
    }
  }

  article .article-image img {
    max-height: 80px;
  }
}

#cms, .page-content.page-cms {
  background: white;
}

.page-cms .page-content .about-us {
  margin-bottom: 0;
}

.supercheckout-new .address-title {
  line-height: 18px;
}

#supercheckout-fieldset .supercheckout-new .payment-additional-info .additional-information {
  background: white;
}

@media (max-width: 767px) {
  .user-login-top {
    span {
      white-space: nowrap;
    }
  }
  #right-side-cart-background {
    .right-cart-summary .totals .total {
      .checkout-url a {
        padding: 12px 0;
        line-height: 19px;

        &:hover {
          line-height: 19px;
        }
      }
    }
  }
}

//discount price needs adjustment on uk (pound symbol is in front of price)
#products .regular-price,
.product-discount .regular-price,
.products .regular-price {
  text-decoration: none !important;

  &::before {
    width: 100%;
    margin-left: 10px;
  }
}

#index {
  .footer-container {
    padding-bottom: 10px;
  }
}